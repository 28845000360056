'use client';

import { useState, useEffect, useCallback } from 'react';

import { API } from 'src/helpers/api';
import { useAuthContext } from 'src/auth/hooks';

import { IGift } from 'src/types/gift';

import SelectGift from './select-gift';
import { getDomainName } from '../../utils/client-common';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
  selectTabGiftWrapper: Function;
  enableSlider?: boolean | undefined;
}

const SelectGiftWrapper = ({selectTabGiftWrapper, enableSlider}: Props) => {
  const { user } = useAuthContext();
  const { state, dispatch } = useAppContext();
  const [gifts, setGifts] = useState<IGift[]>([]);

  useEffect(() => {
    if (user?.id) {
      init();
    }
  }, [user?.id]);

  const init = async () => {
    try {
      const activityId = state.smbActivityId;
      if (user?.id && activityId) {
        const { data } = await API({
          url: `giftSelection`,
          params: {
            activityId: activityId && activityId,
            ssoUserId: user?.id,
            relations: ['merchants', 'categories'],
            status: ['NEW', 'OPENED_BY_CUSTOMER'],
          },
          method: 'GET',
        });
        if (data.data.length) {
          setGifts(data.data);
        } else {
          setGifts([]);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const filteredGifts = gifts.filter((gift: any) => gift.items.some((item: any) => item.show));

  useEffect(() => {
    const updateGiftStatus = async () => {
      try {
        const newGifts = filteredGifts.filter((gift: any) => gift.status === 'NEW');

        const requests = newGifts.map((gift: any) =>
          API({
            url: `/giftSelection/updateStatusToOpen/${gift?.id}`,
            method: 'POST',
          })
        );

        await Promise.all(requests);
      } catch (error) {
        console.error('Error:', error);
      }
    };

      updateGiftStatus();
  }, [filteredGifts]);

  if (!user?.id) return null;
  return (
    <div>
      {filteredGifts.map((gift: IGift) => (
        <SelectGift key={gift.id} gift={gift} customerId={user?.id} refreshData={init} selectTabGiftWrapper={selectTabGiftWrapper} enableSlider={enableSlider}/>
      ))}
    </div>
  );
};

export default SelectGiftWrapper;
