'use client';

import Link from 'next/link';
import { useRef, useState, useEffect } from 'react';
// eslint-disable-next-line
import ReactCodeInput from 'react-verification-code-input';

import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { Box, Stack, Button, TextField, Typography } from '@mui/material';

import Image from '../../../../../../components/image';
import { IMerchant } from '../../../../../../types/merchant';
import { useAuthContext } from '../../../../../../auth/hooks';
import { BlockType } from '../../../../../../types/generator';
import { useBoolean } from '../../../../../../hooks/use-boolean';
import { BasicPageParams } from '../../../../../../types/common';
import { useLocales, useTranslate } from '../../../../../../locales';
import { useContentContext } from '../../../../../../components/content';
import { useGetActivityByDomain } from '../../../../../../api/activities';
import { useGetCustomers } from '../../../../../../redux/slices/customers';
import { getCustomerByPhoneOrMail } from '../../../../../../api/customers';
import { ICustomerBase } from '../../../../../../types/customer-citypeople';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';
import { uploadProductsImage } from '../../../../../../helpers/uploadProductsImage';

interface Props {
  onClose: () => void;
  closingForbidden?: boolean;
}

type Propses = {
  block: BlockType | undefined;
  noImage?: boolean;
  params?: BasicPageParams;
};

const WARNING_TEXT = 'יש לאשר את תנאי השימוש במערכת';

export const NewLogin = ({ block, noImage, params }: Propses) => {
  const { login, checkCode, user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  const { currentLang } = useLocales();
  const [expiration, setExpiration] = useState('30d');

  const {
    authVariantInActivity,
    authVariantInMerchant,
    authVariantAll,
    merchantLogo,
    globalSite,
    useCustomExpiration,
    numberExpiration,
    unitOfTimeExpiration,
  } =
    useContentContext();

  useEffect(() => {
      if (useCustomExpiration) {
        const exp = `${numberExpiration}${unitOfTimeExpiration}`;
        setExpiration(exp);
      }
    },
    [useCustomExpiration,
      numberExpiration,
      unitOfTimeExpiration]);

  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [authUser, setUser] = useState([]);
  const [merchants, setMerchants] = useState<any | IMerchant[]>([]);
  const [isCodeView, setIsCodeView] = useState(false);
  const [checked, setChecked] = useState(false);
  const isWarning = useBoolean(false);
  const merchantId = state?.smbAccount?.merchant?.id;
  const openDialog = useBoolean();
  const { customers } = useGetCustomers(+merchantId);

  const { activity } = useGetActivityByDomain();
  const mainImage: string = block?.mainImage as string;
  const rulesLink: any = block?.rulesLink || '';
  const ref = useRef<any>();
  const { t } = useTranslate();
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const findCustomerByPhone = (users: ICustomerBase[], phones: string) =>
    users?.find(
      (customer: ICustomerBase) =>
        customer &&
        customer?.phones &&
        customer?.phones.some(
          (phoneObj) => phoneObj.type === 'mobile' && phoneObj.number === phones,
        ),
    );
  const checkCustomerActivity = (users: ICustomerBase[], activityId: string, phones: string) =>
    users?.find(
      (customer: ICustomerBase) =>
        customer?.smbActivities?.some((a: { id: string }) => a.id === activityId) &&
        customer?.phones?.some(
          (phoneObj) => phoneObj.type === 'mobile' && phoneObj.number === phones,
        ),
    );

  useEffect(() => {
    if (customers && customers.length) {
      if (findCustomerByPhone(customers, phone)) {
        getCustomerByPhoneOrMail(phone, '', dispatch).then((response) => {
          const allMerchants = response.flatMap(
            (customer: { merchants: IMerchant }) => customer.merchants,
          );
          const uniqueMerchants = Array.from(
            new Map(allMerchants.map((m: IMerchant) => [m.id, m])).values(),
          );
          setMerchants(uniqueMerchants);
          openDialog.onTrue();
        });
      }
    }
  }, [authUser]);

  const executeLogin = async () => {
    if (block?.settings?.enableCheckbox) {
      if (checked) {
        try {
          if (authVariantInActivity || authVariantInMerchant) {
            // await login(phone, activity.id);
            await login(phone);
          } else {
            await login(phone);
          }
          setIsCodeView(true);
        } catch (e) {
          console.error(e);
        }
      } else {
        isWarning.onTrue();
        setTimeout(isWarning.onFalse, 3000);
      }
    } else {
      try {
        if (authVariantInActivity || authVariantInMerchant) {
          // await login(phone, activity.id);
          await login(phone);
        } else {
          await login(phone);
        }
        setIsCodeView(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getCode = async () => {
    if (customers && customers.length) {
      const matchedCustomer = findCustomerByPhone(customers, phone);
      const isActiveCustomer = checkCustomerActivity(customers, activity.id, phone);
      if (authVariantAll) {
        executeLogin();
      } else if (authVariantInMerchant) {
        if (!matchedCustomer) {
          setError('You are not a member of this merchant');
        }
        if (authVariantInActivity) {
          if (!isActiveCustomer) {
            setError('You are not a member of this merchant and activity');
          } else {
            executeLogin();
          }
        } else {
          executeLogin();
        }
      } else if (authVariantInActivity) {
        if (!isActiveCustomer) {
          setError('You are not a member in this activity');
        } else {
          executeLogin();
        }
      } else {
        executeLogin();
      }
    }
  };

  const sendCode = async (code: string) => {
    try {
      if (!globalSite) {
        await checkCode(phone, code, expiration);
        setActiveDialog(null);
        // window.location.reload();
      } else {
        await checkCode(phone, code, expiration).then((response: any) => {
          if (response.user) {
            setUser(response.user);
            openDialog.onTrue();
          }
        });
        // setActiveDialog(null);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkMerchant = (merchant: IMerchant) => {
    if (merchantLogo) {
      localStorage.setItem('logoImage', merchant.mainImageURL);
    }
    localStorage.setItem('merchantId', merchantId);
    setActiveDialog(null);
  };

  if (user && globalSite) {
    return (
      <Box>
        <Typography mb={5} sx={{ width: 1, textAlign: 'center' }}>
          You are logged in by phone <b>{user.phone}</b>
        </Typography>
        <Stack>
          {!user && (
            <Typography sx={{ width: 1, textAlign: 'center', fontSize: '22px', fontWeight: 800 }}>
              Select a merchant
            </Typography>
          )}
          <Box
            sx={{
              maxHeight: '300px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
            }}
          >
            <Grid container spacing={2}>
              {merchants &&
                merchants.map((merchant: IMerchant) => (
                  <Grid
                    item
                    xs={6}
                    md={6}
                    key={merchant.id}
                    sx={{
                      cursor: 'pointer',
                      justifyContent: 'center',
                      padding: '10px',
                    }}
                    onClick={() => checkMerchant(merchant)}
                  >
                    <Image height={150} src={`${uploadProductsImage(merchant.mainImageURL)}`} />
                    <Typography sx={{ width: '100%', textAlign: 'center' }}>
                      {merchant.title}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Stack>
      </Box>
    );
  }

  const isMaybeNext = !(phone.length > 9);

  let align;
  if (block?.settings?.imageAlign === 'start') align = 'flex-start';
  if (block?.settings?.imageAlign === 'end') align = 'flex-end';
  if (block?.settings?.imageAlign === 'center') align = 'center';

  return (
    <Box>
      {!noImage && (
        <Stack alignItems={align}>
          <Image src={mainImage || ''} sx={{ width: '50%' }} />
        </Stack>
      )}
      {!isCodeView && (
        <Stack p={3} gap={2}>
          <Typography
            color="primary"
            sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'start' }}
          >
            אימות חשבון
          </Typography>
          <TextField
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setError('');
            }}
            id="outlined-basic"
            label="מספר הטלפון"
            variant="outlined"
            helperText={isWarning.value ? WARNING_TEXT : 'הקלד 10 ספרות'}
            sx={block?.settings?.enableLogin ? { '& .MuiFormHelperText-root': { color: 'black' } } : {}}
            FormHelperTextProps={{
              style: { ...(isWarning.value && { color: 'red' }) },
            }}
          />
          {!!error.length && (
            <Typography sx={{ width: '100%', fontSize: '11px', color: 'red', textAlign: 'center' }}>
              {error}
            </Typography>
          )}
          {block?.settings?.enableCheckbox && (
            <Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                <Typography sx={{ fontSize: '16px' }}>אני מאשר/מאשרת תנאי שימוש במערכת</Typography>
              </Stack>
              <Link href={rulesLink} passHref>
                <Typography
                  component="a"
                  sx={{ textDecoration: 'underline', mx: 6, color: 'black' }}
                  onClick={() => setActiveDialog(null)}
                >
                  לקריאה תקנון השימוש
                </Typography>
              </Link>
            </Stack>
          )}
          <Stack direction="row" gap={2}>
            <Button
              sx={{ height: '40px', padding: 0 }}
              onClick={getCode}
              variant="contained"
              color="primary"
              disabled={isMaybeNext}
            >
              כניסה
            </Button>
            {/* <Stack direction="row" gap={2}> */}
            {/*  <Button */}
            {/*    sx={{ height: '40px', padding: 0 }} */}
            {/*    onClick={getCode} */}
            {/*    variant="contained" */}
            {/*    color="primary" */}
            {/*    disabled={isMaybeNext} */}
            {/*  > */}
            {/*    כניסה */}
            {/*  </Button> */}
            {/*  {block?.settings?.enableCheckbox && ( */}
            {/*    <Stack> */}
            {/*      <Stack direction="row" gap={1} alignItems="center"> */}
            {/*        <Checkbox checked={checked} onChange={() => setChecked(!checked)} /> */}
            {/*        <Typography sx={{ fontSize: '16px' }}> */}
            {/*          אני מאשר/מאשרת תנאי שימוש במערכת */}
            {/*        </Typography> */}
            {/*      </Stack> */}
            {/*      <Link href={rulesLink} passHref> */}
            {/*        <Typography */}
            {/*          component="a" */}
            {/*          sx={{ textDecoration: 'underline', mx: 6, color: 'black' }} */}
            {/*          onClick={() => setActiveDialog(null)} */}
            {/*        > */}
            {/*          לקריאה תקנון השימוש */}
            {/*        </Typography> */}
            {/*      </Link> */}
            {/*    </Stack> */}
            {/*  )} */}
            {/* </Stack> */}
          </Stack>
        </Stack>
      )}

      {isCodeView && (
        <Stack alignItems="center">
          <Typography mb={5}>הזן קוד</Typography>
          <Box mb={5} sx={{ direction: currentLang?.value === 'he' ? 'rtl' : 'ltr' }}>
            <ReactCodeInput
              ref={ref}
              autoFocus
              fields={6}
              onComplete={(code) => sendCode(code)}
              // onChange={() => setError(null)}
            />
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default NewLogin;
