'use client';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Ib2bProduct } from '../../types/b2b';
import { useRouter } from '../../routes/hooks';
import { useGetProduct } from '../../api/product';
import { BlockType } from '../../types/page-generator';
import { useResponsive } from '../../hooks/use-responsive';
import { extractPrices } from '../../helpers/extractPrices';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';

type Props = {
  block: BlockType | undefined;
  product: Ib2bProduct;
};

export const AlternativeProduct = ({ block, product }: Props) => {
  // const { product: product } = useGetProduct(product?.id);
  const isMobile = useResponsive('down', 'sm');
  const isLaptop = useResponsive('down', 'xs');
  const router = useRouter();

  const imageLink =
    (product?.mainImageURL
      ? `${uploadProductsImage(`${product?.mainImageURL}`)}`
      : product?.merchants &&
        product?.merchants.length &&
        `${uploadProductsImage(`${product?.merchants[0]?.mainImageURL}`)}`) || '';
  const { price, basePrice, businessPrice } = extractPrices(product?.prices);
  const length = block?.settings?.partialSize ? 10 : 20;
  const pointsLength = block?.settings?.partialSize
    ? product?.title.length > 10
    : product?.title.length > 20;
  const sliceLength = isMobile ? 10 : 45;
  const title = !block?.settings?.partialSize
    ? product?.title.slice(0, sliceLength)
    : product?.title.slice(0, length);
  const points = pointsLength ? '...' : '';
  return (
    <Grid
      item
      xs={block?.settings?.partialSize && !isMobile ? 2 : 4}
      md={block?.settings?.partialSize && !isMobile ? 2 : 4}
      display="flex"
      width={1}
      alignItems="center"
      sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    >
      <Stack width={1} alignItems="center" height={!isMobile ? '100%' : '98px'}>
        <Box
          onClick={() => {
            router.push(`/product/${product.id}`);
          }}
          sx={{
            backgroundSize: 'cover',
            backgroundImage: `url(${imageLink})`,
            backgroundRepeat: 'no-repeat',
            height: {
              xs: '100%',
              md: block?.settings?.partialSize && !isMobile ? '100px' : '180px',
            },
            borderRadius: '10px',
            transform: block?.settings?.enableRotation && 'rotate(-20deg)',
            mt: block?.settings?.enableRotation && '50px',
            width: block?.settings?.enableRotation ? '85%' : '100%',
            mx: block?.settings?.enableRotation && 'auto',
            cursor: 'pointer',
          }}
        />
      </Stack>
      {isMobile ? (
        <>
          <Typography
            sx={{
              width: '100%',
              wordBreak: 'break-word',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '26px',
              direction: 'ltr',
            }}
          >
            {`${product?.title}`}
          </Typography>
          {block?.settings?.enablePrice && <Typography sx={{width: '100%', direction: 'ltr'}}>שובר ₪{price}</Typography>}
        </>
      ) : (
        <>
          <Tooltip title={product?.title}>
            <Typography
              sx={{
                width: '100%',
                wordBreak: 'break-word',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '26px',
                cursor: 'pointer',
                direction: 'ltr'
              }}
            >
              {`${product?.title}`}
            </Typography>
          </Tooltip>
          {block?.settings?.enablePrice && <Typography sx={{width: '100%', direction: 'ltr'}}>שובר ₪{price}</Typography>}
        </>
      )}
    </Grid>
  );
};
