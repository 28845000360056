'use client';

import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField, ButtonGroup } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { addToBasket } from 'src/api/basket';
import { useAuthContext } from 'src/auth/hooks';
import { extractPrices } from 'src/helpers/extractPrices';
import HotIcon from 'src/assets/icons/happy-gift/hotIcon';
import { generateOptions } from 'src/helpers/generateOptions';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { BlockType } from 'src/types/page-generator';
import { Ib2bProduct, ProvidersEnum } from 'src/types/b2b';

import Delivery from '../b2c-shop/view/delivery';
import { useGetProduct } from '../../api/product';
import { useBoolean } from '../../hooks/use-boolean';
import { useContentContext } from '../../components/content';
import { ICheckoutDeliveryOption } from '../../types/checkout';

type openPrice = {
  price: string;
  title: string;
  value: string;
};

type Props = {
  block: BlockType | undefined;
  // TODO: product should not be any
  product: any;
  mode: string;
  handleOpenPriceChange: Function;
  openPriceProducts?: openPrice[] | any;
};

export const BannerProductCardCustomMode = ({
  block,
  product,
  mode,
  handleOpenPriceChange,
  openPriceProducts,
}: Props) => {
  const { t } = useTranslate();
  const defaultLimit = 1;
  // const isStock = product.provider === ProvidersEnum.STOCK;
  const isStock = product?.useStock;
  const limit =
    isStock && product.stock ? +product.stock : defaultLimit;
  const OPTIONS = generateOptions(1, limit);
  const { dispatch, state } = useAppContext();
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const { cart } = state;
  const router = useRouter();
  const [count, setCount] = useState<string>('');
  const isInCart = checkIfProductInCart(cart, product.id);
  const openDeliveryModal = useBoolean();
  const [deliveryOptions, setProductDeliveryOptions] = useState<ICheckoutDeliveryOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ICheckoutDeliveryOption>({
    id: '1',
    value: 0,
    label: 'Free',
    description: '5-7 Days delivery',
    imageUrl: '',
  });
  const { product: localProduct } = useGetProduct(product?.id);
  const settings = (): any => {
    if (!block?.settings?.generalSettings) return block?.settings;
    return product;
  };

  const { enableTimer, timerBgcolor, timerColor, variant } = settings();

  const {
    businessPrice,
    basePrice: discountPrice,
    price,
    maxPrice,
    minPrice,
  } = extractPrices(product?.prices);
  const priceToDisplay = businessPrice || discountPrice || price;
  const isOpenPrice = !businessPrice && !price && !!maxPrice && !!minPrice;

  const allPrice = product?.prices ? product?.prices[0]?.value : '';

  const imageLink =
    (product.mainImageURL
      ? `${uploadProductsImage(`${product.mainImageURL}`)}`
      : product.merchants &&
        product.merchants.length &&
        `${uploadProductsImage(`${product.merchants[0].mainImageURL}`)}`) ||
    '/assets/images/product-default.png';

  const localDataString: string | null = typeof window !== 'undefined' ? localStorage.getItem('openPrice') : '';
  const localData: any = localDataString ? JSON.parse(localDataString) : [];

  const value: number | undefined = localData.find(
    (item: openPrice) => item.value === product.id
  )?.price;

  const coincidence = openPriceProducts?.find((item: openPrice) => item.value === product.id);

  let currentPriceView = coincidence?.price;
  if (coincidence && maxPrice && coincidence?.price > maxPrice) currentPriceView = maxPrice;
  if (coincidence && minPrice && coincidence?.price < minPrice) currentPriceView = minPrice;

  useEffect(() => {
    const current = localData?.find((item: openPrice) => item.value === product.id);
    setCount(current?.price);
  }, [state]);

  const info = (val: Ib2bProduct, localBlock: BlockType | undefined) => {
    localStorage.setItem('product', JSON.stringify(val));
    if (localBlock !== undefined) {
      localStorage.setItem('settings', JSON.stringify(localBlock));
    }
    router.push(`/product/${val.id}`);
  };

  const handleClick = () => {
    const currentPageUrl = window.location.href;
    let params = '?';
    if (product?.enableDiscount) params += params === '?' ? 'ds' : ',ds';
    if (product?.enableBalance) params += params === '?' ? 'ba' : ',ba';
    if (product?.enableCount) params += params === '?' ? 'co' : ',co';
    if (product?.enableAvailable) params += params === '?' ? 'av' : ',av';
    if (product?.enableAvailableLimit) params += params === '?' ? 'av' : ',avl';
    const url = `${currentPageUrl}product/${product.id}/${params}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('Copied');
      })
      .catch((error) => {
        console.error('Failed to copy: ', error);
      });
  };

  const addToCart = async (locProduct: Ib2bProduct) => {
    openDeliveryModal.onTrue();
    setProductDeliveryOptions(localProduct.deliveryOptions);
  };

  const handleDeliveryChange = (option: any) => {
    setSelectedOption(option);
  };

  return (
    <Grid item md={3}>
      <Card>
        {enableTimer && (
          <Stack
            direction="row-reverse"
            alignItems="center"
            justifyContent={enableTimer ? 'space-between' : 'center'}
          >
            <ButtonGroup
              variant="contained"
              sx={{
                borderRadius: '5px',
                '& .MuiButtonBase-root': {
                  '&:first-of-type': { borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' },
                  '&:last-of-type': { borderTopRightRadius: '5px', borderBottomRightRadius: '5px' },
                  // borderRadius: '5px',
                  backgroundColor: timerBgcolor,
                  color: timerColor,
                  height: '25px',
                  alignItems: 'center',
                  fontSize: '10px',
                  p: 0.5,
                  '&:hover': {
                    backgroundColor: timerBgcolor,
                    color: timerColor,
                  },
                },
              }}
            >
              <Button>10 שעות</Button>
              <Button>10 דקות</Button>
              <Button>10 שניות</Button>
            </ButtonGroup>
            {product.enableBrands ? (
              <Image src={product && product?.brand} sx={{ width: '30%', height: '50px' }} />
            ) : (
              <Box sx={{ height: '50px' }} />
            )}
          </Stack>
        )}
        <Stack sx={{ p: 1 }} gap={1}>
          <Image
            onClick={() => {
              info(product && product, product);
            }}
            src={imageLink}
            sx={{
              height: '180px',
              cursor: 'pointer',
              '& img': { objectFit: 'unset' },
            }}
          />
          {product.enableHotImage && (
            <HotIcon
              color={product.hotImageColor}
              bgcolor={product.hotImageBgcolor}
              sx={{
                position: 'absolute',
                width: '11%',
                height: '7%',
                top: enableTimer ? '60px' : '20px',
                left: '10px',
              }}
            />
          )}
          <Typography
            sx={{
              color: product.contentTextColor,
              fontSize: product.contentFontSize,
              fontWeight: product.contentFontWeight,
              height: '60px',
              fontStyle: product.contentFontStyle,
              textDecoration: product.contentTextDecoration,
            }}
          >
            {product && product?.title}
          </Typography>
          {variant === 'v1' ? (
            <Stack
              width={1}
              direction="row-reverse"
              justifyContent="space-between"
              gap={1}
              alignItems="center"
            >
              {product.enableButton && (
                <>
                  {isInCart ? (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        height: '34px',
                        width: '70%',
                        borderRadius: '25px',
                        fontWeight: 400,
                      }}
                      endIcon={<Iconify icon="ep:success-filled" />}
                    >
                      בעגלה
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size={product.buttonSize}
                      onClick={() => {
                        if (!user) {
                          dispatch({
                            type: ActionTypes.SET_ACTIVE_DIALOG,
                            payload: 'login',
                          });
                        } else if (!block?.settings?.linkOnProductInfo) {
                          // if (isOpenPrice && !coincidence) return;
                          let currentPrice;

                          if (value && !coincidence) {
                            currentPrice = value;
                            if (minPrice && value < minPrice) currentPrice = minPrice;
                            if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                          } else if (isOpenPrice && coincidence) {
                            currentPrice = coincidence.price;
                            if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                            if (+coincidence.price < minPrice) currentPrice = minPrice;
                          } else {
                            currentPrice = (product?.enableDiscount && discountPrice) || price;
                          }
                          if (localProduct?.deliveryOptions?.length) {
                            addToCart(product);
                          } else {
                            addToBasket(
                              state.smbAccount.id,
                              product.id,
                              currentPrice,
                              imageLink,
                              dispatch,
                              cart,
                              []
                            );
                          }
                        } else info(product, block);
                      }}
                      sx={{
                        borderRadius: product.buttonBorderRadius || '20px',
                        height: '30px',
                        width: '70%',
                        background: product.buttonBgColor,
                        color: product.buttonColor,
                        fontWeight: 400,
                        '&:hover': {
                          background: product.buttonBgColor,
                          color: product.buttonColor,
                        },
                      }}
                      endIcon={<Iconify icon={product.buttonIcon} />}
                    >
                      {product.buttonLabel}
                    </Button>
                  )}
                </>
              )}
              {product?.enablePrice && (
                <>
                  {!isOpenPrice && (
                    <Stack width={0.3}>
                      {product.enableDiscount && (
                        <Typography
                          sx={{
                            color: product.topPriceColor,
                            textDecoration: 'line-through',
                            fontSize: product.topPriceFontSize,
                          }}
                        >
                          ₪{price}
                        </Typography>
                      )}
                      {product.enableDiscount ? (
                        <Typography
                          sx={{
                            color: product.bottomPriceColor,
                            fontSize: product.bottomPriceFontSize,
                          }}
                        >
                          ₪{priceToDisplay}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: product.bottomPriceColor,
                            fontSize: product.bottomPriceFontSize,
                          }}
                        >
                          ₪{block?.settings.enableSwitch ? allPrice : price}
                        </Typography>
                      )}
                    </Stack>
                  )}
                  {isOpenPrice && (
                    <>
                      {coincidence && coincidence?.price ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography
                            sx={{
                              color: block?.settings?.bottomPriceColor,
                              fontSize: block?.settings?.bottomPriceFontSize,
                            }}
                          >
                            ₪{coincidence && coincidence?.price && currentPriceView}
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <TextField
                            name="openPrice"
                            // placeholder={`${t('Sum')}`}
                            placeholder='הזן סכום'
                            sx={{ width: 60, '& input': { p: 1 } }}
                            value={count}
                            onChange={(e) => {
                              handleOpenPriceChange(e, product, maxPrice, minPrice);
                              setCount(e.target.value);
                            }}
                          />
                          <Typography
                            sx={{
                              color: 'text.secondary',
                              fontSize: 16,
                            }}
                          >
                            ₪
                          </Typography>
                        </Stack>
                      )}
                    </>
                  )}
                </>
              )}
            </Stack>
          ) : (
            <Stack
              direction="column"
              justifyContent="space-between"
              gap={1}
              alignItems="center"
              width={1}
            >
              {product.enablePrice && (
                <Stack width={0.3}>
                  {product.enableDiscount && (
                    <Typography
                      sx={{
                        color: product.topPriceColor,
                        textDecoration: 'line-through',
                        fontSize: product.topPriceFontSize,
                      }}
                    >
                      ₪{price}
                    </Typography>
                  )}
                  {product.enableDiscount ? (
                    <Typography
                      sx={{
                        color: product.bottomPriceColor,
                        fontSize: product.bottomPriceFontSize,
                      }}
                    >
                      ₪{discountPrice || businessPrice}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: product.bottomPriceColor,
                        fontSize: product.bottomPriceFontSize,
                      }}
                    >
                      ₪{block?.settings.enableSwitch ? allPrice : price}
                    </Typography>
                  )}
                </Stack>
              )}
              {product.enableButton && (
                <Button
                  variant="contained"
                  fullWidth
                  size={product.buttonSize}
                  sx={{
                    width: '70%',
                    borderRadius: product.buttonBorderRadius || '20px',
                    height: '30px',
                    background: product.buttonBgColor,
                    color: product.buttonColor,
                    fontWeight: 400,
                    fontSize: '12px',
                    '&:hover': {
                      background: product.buttonBgColor,
                      color: product.buttonColor,
                    },
                  }}
                  endIcon={<Iconify icon={product.buttonIcon} />}
                  onClick={() => {
                    if (!user) {
                      dispatch({
                        type: ActionTypes.SET_ACTIVE_DIALOG,
                        payload: 'login',
                      });
                    } else if (!block?.settings?.linkOnProductInfo) {
                      // if (isOpenPrice && !coincidence) return;
                      let currentPrice;

                      if (value) {
                        currentPrice = value;
                        if (minPrice && value < minPrice) currentPrice = minPrice;
                        if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                      } else if (isOpenPrice && coincidence) {
                        currentPrice = coincidence.price;
                        if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                        if (+coincidence.price < minPrice) currentPrice = minPrice;
                      } else {
                        currentPrice = (product?.enableDiscount && discountPrice) || price;
                      }
                      if (product?.deliveryOptions?.length) {
                        addToCart(product);
                      } else {
                        addToBasket(
                          state.smbAccount.id,
                          product.id,
                          currentPrice,
                          imageLink,
                          dispatch,
                          cart,
                          []
                        );
                      }
                    } else info(product, block);
                  }}
                >
                  {product.buttonLabel}
                </Button>
              )}
            </Stack>
          )}
          {variant === 'v1' && (
            <Grid container>
              <Grid item md={6}>
                {product.enableBalance && (
                  <Stack direction="row">
                    <Typography>נשאר: 199</Typography>
                  </Stack>
                )}
                {product?.enableShare && (
                  <Iconify
                    icon="solar:share-bold"
                    width={16}
                    sx={{ mr: 0.5 }}
                    onClick={handleClick}
                  />
                )}
              </Grid>
              <Grid item md={6}>
                <Stack
                  sx={{
                    display: !product.enableAvailable && !product.enableCount ? 'none' : '',
                  }}
                >
                  {product.enableCount && (
                    <Stack direction="row" justifyContent="space-between">
                      <Typography sx={{ mx: 1 }}>כמות</Typography>
                      <TextField
                        sx={{
                          '& .MuiNativeSelect-select': { py: 0 },
                        }}
                        select
                        fullWidth
                        SelectProps={{
                          native: true,
                          sx: { textTransform: 'capitalize' },
                        }}
                        onChange={() => {}}
                      >
                        {OPTIONS.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </TextField>
                    </Stack>
                  )}
                  {product.enableAvailable && (
                    <Typography
                      sx={{
                        fontSize: !product?.enableCount ? '15px' : '10px',
                        textAlign: 'end',
                      }}
                    >
                      ניתן לקנייה: 1
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
          )}
          {variant === 'v1' && product.enableSocialLinks && (
            <Stack direction="row-reverse" gap={1} alignItems="flex-end">
              <Stack direction="row" justifyContent="flex-start" sx={{ mt: 2 }} spacing={1}>
                {product?.socialLinks?.map((socialLink: any, idx: any) => (
                  <Box
                    key={`socialLinkItem_${idx}`}
                    bgcolor={product?.customSocialLinkColor ? product.socialLinksBgcolor : ''}
                    borderRadius={50}
                    width={28}
                    height={28}
                    display="flex"
                    flexDirection="row"
                    // component={Link}
                    // href={socialLink.path}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      ':hover': {
                        bgcolor: product?.customSocialLinkColor ? product.socialLinksBgcolor : '',
                        transition: '.25s ease all',
                        cursor: 'pointer',
                        svg: {
                          color: product?.customSocialLinkColor ? product.socialLinksColor : '',
                        },
                      },
                    }}
                  >
                    <Iconify
                      width={16}
                      height={16}
                      color={product?.customSocialLinkColor ? product.socialLinksColor : ''}
                      icon={socialLink.icon ?? 'material-symbols-light:link'}
                    />
                  </Box>
                ))}
              </Stack>
              <Typography
                sx={{
                  color: product.socialContentTextColor,
                  fontSize: product.socialContentFontSize,
                  fontWeight: product.socialContentFontWeight,
                  fontStyle: product.socialContentFontStyle,
                  textDecoration: product.socialContentTextDecoration,
                }}
              >
                {product.socialContentTitle || 'שתפו את הבום עם חברים'}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Card>
      <Modal open={openDeliveryModal.value} onClose={() => openDeliveryModal.onFalse()}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid gray.500',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Delivery
            options={deliveryOptions || []}
            isDeliveryEnable
            onChange={(option) => handleDeliveryChange(option)}
            selectedOption={selectedOption}
          />
          <Button
            disabled={selectedOption.id === '1'}
            variant="contained"
            size={block?.settings?.buttonSize}
            sx={{
              width: '70%',
              borderRadius: block?.settings?.buttonBorderRadius,
              height: '30px',
              background: block?.settings?.buttonBgColor,
              color: block?.settings?.buttonColor,
              fontWeight: 400,
              fontSize: '12px',
              '&:hover': {
                background: block?.settings?.buttonBgColor,
                color: block?.settings?.buttonColor,
              },
            }}
            endIcon={<Iconify icon={block?.settings?.buttonIcon} />}
            onClick={() => {
              if (!user) {
                dispatch({
                  type: ActionTypes.SET_ACTIVE_DIALOG,
                  payload: 'login',
                });
              } else if (!block?.settings?.linkOnProductInfo) {
                // if (isOpenPrice && !coincidence) return;
                let currentPrice;
                if (value) {
                  currentPrice = value;
                  if (minPrice && value < minPrice) currentPrice = minPrice;
                  if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                } else if (isOpenPrice && coincidence) {
                  currentPrice = coincidence.price;
                  if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                  if (+coincidence.price < minPrice) currentPrice = minPrice;
                } else {
                  currentPrice = (block?.settings?.enableDiscount && discountPrice) || price;
                }
                addToBasket(
                  state.smbAccount.id,
                  product.id,
                  currentPrice,
                  imageLink,
                  dispatch,
                  cart,
                  [],
                  selectedOption.id,
                ).then(() => openDeliveryModal.onFalse());
              } else info(product, block);
            }}
          >
            {block?.settings?.buttonLabel}
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
};
