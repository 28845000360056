'use client';

import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Dialog, IconButton, TextField } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { extractPrices } from 'src/helpers/extractPrices';
import { generateOptions } from 'src/helpers/generateOptions';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';
import { checkIfProductInCart } from 'src/helpers/checkIfProductInCart';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { Ib2bProduct } from 'src/types/b2b';
import { BlockType } from 'src/types/page-generator';

import { useBoolean } from '../../hooks/use-boolean';
import { useResponsive } from '../../hooks/use-responsive';
import { useContentContext } from '../../components/content';
import { ICheckoutDeliveryOption } from '../../types/checkout';
import { addToBasket } from '../../api/basket';

type openPrice = {
  price: string;
  title: string;
  value: string;
};

interface ProductOpenPrice {
  price: number;
  title: string;
  value: string;
}

type Props = {
  block: BlockType;
  localSettings?: BlockType;
  product: any;
  mode: string;
  openPriceProducts?: openPrice[] | any;
};


export const ProductCard2 = ({
                               block,
                               product,
                               mode,
                               localSettings,
                               openPriceProducts,
                             }: Props) => {
  const { user } = useAuthContext();
  const { openSite } = useContentContext();
  const router = useRouter();
  const { t } = useTranslate();
  const defaultLimit = 0;
  // const isStock = product?.provider === ProvidersEnum.STOCK;
  const isStock = product?.useStock;
  const isMobile = useResponsive('down', 'sm');
  const countriesView = useBoolean();
  const limit =
    isStock && product?.stock ? +product.stock : defaultLimit;
  const OPTIONS = generateOptions(1, limit);
  const openDeliveryModal = useBoolean();
  const asterisksInfo = useBoolean();
  const { dispatch, state } = useAppContext();
  const { cart } = state;
  const [count, setCount] = useState<string>('');
  const [searchText, setSearchText] = useState('');
  const [deliveryOptions, setProductDeliveryOptions] = useState<ICheckoutDeliveryOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ICheckoutDeliveryOption>({
    id: '1',
    value: 0,
    label: 'Free',
    description: '5-7 Days delivery',
    imageUrl: '',
  });
  const localDataString: string | null = typeof window !== 'undefined' ? localStorage.getItem('openPrice') : '';
  const localData: any = localDataString ? JSON.parse(localDataString) : [];
  const isInCart = checkIfProductInCart(cart, product?.id);
  // const { product: localProduct } = useGetProduct(product?.id);
  const settings = () => {
    if (mode !== 'notCustom' && !localSettings?.settings?.generalSettings) return block;
    if (mode !== 'notCustom' && localSettings?.settings?.generalSettings)
      return localSettings.settings;
    return block?.settings;
  };
  const info = (value: Ib2bProduct, localBlock: BlockType | undefined) => {
    localStorage.setItem('product', JSON.stringify(value));
    if (localBlock !== undefined) {
      localStorage.setItem('settings', JSON.stringify(localBlock));
    }
    router.push(`/product/${value.id}`);
  };

  const value: number | undefined = localData.find(
    (item: ProductOpenPrice) => item.value === product?.id,
  )?.price;

  const coincidence = openPriceProducts?.find((item: openPrice) => item.value === product?.id);

  useEffect(() => {
    const updatedCard = state?.openPricesProducts?.map((item: ProductOpenPrice) => {
      if (item.value === product?.id) {
        return {
          ...item,
          price: count,
        };
      }
      return item;
    });
    dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload: updatedCard });
  }, [count]);
  useEffect(() => {
    const current = localData?.find((item: openPrice) => item.value === product?.id);
    setCount(current?.price);
  }, [state]);

  const {
    contentTextColor,
    contentFontSize,
    contentFontWeight,
    contentFontStyle,
    contentTextDecoration,
    contentAlign,
    buttonBorderRadius,
    buttonBgColor,
    buttonColor,
    enableButton,
    enableCount,
    enableDiscount,
    enableBalance,
    enableAvailable,
    enableAvailableLimit,
    buttonLabelNoPrice,
  } = settings();

  const { businessPrice, basePrice, price, maxPrice, minPrice } = extractPrices(product?.prices);

  // const isOpenPrice = !businessPrice && !price && !!maxPrice && !!minPrice;
  const isOpenPrice = !businessPrice && !price;

  const allPrice = product?.prices ? product?.prices[0]?.value : '';

  let currentPriceView = coincidence?.price;
  if (coincidence && maxPrice && coincidence?.price > maxPrice) currentPriceView = maxPrice;
  if (coincidence && minPrice && coincidence?.price < minPrice) currentPriceView = minPrice;

  const imageLink =
    (product?.mainImageURL
      ? `${uploadProductsImage(`${product?.mainImageURL}`)}`
      : product?.merchants &&
      product?.merchants.length &&
      `${uploadProductsImage(`${product?.merchants[0].mainImageURL}`)}`) || '';

  const handleClick = () => {
    const currentPageUrl = window.location.href;
    let params = '?';
    if (enableDiscount) params += params === '?' ? 'ds' : ',ds';
    if (enableBalance) params += params === '?' ? 'ba' : ',ba';
    if (enableCount) params += params === '?' ? 'co' : ',co';
    if (enableAvailable) params += params === '?' ? 'av' : ',av';
    if (enableAvailableLimit) params += params === '?' ? 'av' : ',avl';
    const url = `${currentPageUrl}product/${product?.id}/${params}`;
    if (navigator.share) {
      navigator
        ?.share({
          url,
        })
        .then(() => console.log(''))
        .catch((error) => console.error('Error', error));
    } else {
      navigator.clipboard
        ?.writeText(url)
        ?.then(() => {
        })
        .catch((error) => {
          console.error('Failed to copy: ', error);
        });
    }
  };

  const addToCart = async (locProduct: Ib2bProduct) => {
    console.log('addToCart');
    openDeliveryModal.onTrue();
    setProductDeliveryOptions(product?.deliveryOptions);
  };

  const handleDeliveryChange = (option: any) => {
    setSelectedOption(option);
  };

  const coverageAttribute = product?.attributes?.find(
    (attr: { name: string }) =>
      attr.name.includes('Coverage') || attr.name.includes('Coating'),
  );
  const dataAttribute = product?.attributes?.find(
    (attr: { name: string }) =>
      attr.name.includes('Data') || attr.name.includes('Tariff'),
  );
  const validityAttribute = product?.attributes?.find((attr: { name: string }) =>
    attr.name.includes('Validity'),
  );

  const filteredCoverage = product?.attributeValues?.filter(
    (attrValue: { attributeId: string }) =>
      attrValue.attributeId === coverageAttribute?.id,
  ) || [];
  const filteredData = product?.attributeValues?.filter(
    (attrValue: { attributeId: string }) =>
      attrValue.attributeId === dataAttribute?.id,
  ) || [];
  const filteredValidity = product?.attributeValues?.filter(
    (attrValue: { attributeId: string }) =>
      attrValue.attributeId === validityAttribute?.id,
  ) || [];
  const changeCountries = () => {
    countriesView.onTrue();
  };

  const countries = Array.isArray(filteredCoverage) && filteredCoverage && filteredCoverage
    .filter((item: { value: string }) =>
      item?.value.toLowerCase().includes(searchText.toLowerCase()),
    )
    .map((item: { value: string }) => ({
      value: item.value,
      flag: `/assets/flags/${item.value}.png`,
    }))
    .sort((a: { value: string; }, b: { value: string; }) => a.value.localeCompare(b.value));

  const closeDialog = () => {
    countriesView.onFalse();
    setSearchText('');
  };

  return (
    <Grid item xs={12} md={block?.settings?.enableSlider ? 12 : 4}>
      <Card
        sx={{
          position: 'relative',
          borderRadius: '5px',
          p: 2,
          maxWidth: '360px',
          cursor: 'pointer',
          boxShadow: 5,
          maxHeight: '385px',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          background:
            'linear-gradient(90deg, rgb(15, 108, 160) 0px, rgb(10, 129, 208))',
          overflow: 'visible',
          mt: '40px',
        }}
      >
        <Image
          onClick={() => {
            info(product && product, block);
          }}
          src={imageLink}
          sx={{
            width: '125px',
            height: 'auto',
            borderRadius: '5px',
            position: 'absolute',
            top: '-30px',
            left: '10%',
          }}
        />
        <Box sx={{ height: '60px' }}>
          <Typography
            sx={{
              height: '100%',
              my: 1,
              mx: 3,
              color: contentTextColor || 'white',
              fontSize: contentFontSize,
              fontWeight: contentFontWeight,
              fontStyle: contentFontStyle,
              textDecoration: contentTextDecoration,
              flex: 1,
              textAlign: contentAlign,
              // transform: block?.settings?.enableRotation && 'rotate(-20deg)',
              mt: block?.settings?.enableRotation && '60px',
            }}
          >
            {product?.title}
          </Typography>
        </Box>
        <Stack sx={{ color: 'white', '& p': { fontWeight: 1000, my: '10px' } }}>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Divider sx={{ color: 'white', width: '100%' }} />
            <Grid item xs={6} md={6}>
              <Button sx={{ px: 0 }} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                changeCountries();
              }}>
                <Stack
                  direction="row"
                  gap={1}
                  sx={{
                    border: '1px solid white',
                    justifyContent: 'center',
                    borderRadius: '5px',
                    px: 1,
                  }}
                >
                  <Typography sx={{ fontSize: '10px' }}>countries</Typography>
                  <Typography
                    sx={{ fontSize: '10px' }}
                  >{`${filteredCoverage?.length}`}</Typography>
                </Stack>
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Stack
                gap={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography sx={{ textAlign: 'end' }}>Coverage</Typography>
                <Iconify icon="mingcute:earth-2-fill" />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Divider sx={{ color: 'white', width: '100%' }} />
            <Grid item xs={6} md={6}>
              <Stack direction="row" gap={1}>
                <Typography>GB</Typography>
                <Typography>{filteredData[0]?.value || ''}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} md={6}>
              <Stack
                gap={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography sx={{ textAlign: 'end' }}>Data</Typography>
                <Iconify icon="lets-icons:sort-arrow" />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Divider sx={{ color: 'white', width: '100%' }} />
            <Grid item xs={6} md={6}>
              <Stack direction="row" gap={1}>
                <Typography>Days</Typography>
                <Typography>{filteredValidity[0]?.value || ''}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} md={6}>
              <Stack
                gap={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography sx={{ textAlign: 'end' }}>Validity</Typography>
                <Iconify icon="heroicons-solid:calendar" />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Divider sx={{ color: 'white', width: '100%' }} />
            <Grid item xs={6} md={6}>
              <Stack direction="row" gap={1}>
                <Typography>USD</Typography>
                <Typography>{price ? parseFloat(price.toString()).toFixed(2) : '0.00'}</Typography>
                <Typography>$</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} md={6}>
              <Stack
                gap={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography sx={{ textAlign: 'end' }}>Price</Typography>
                <Iconify icon="ion:pricetags" />
              </Stack>
            </Grid>
            <Divider sx={{ color: 'white', width: '100%' }} />
          </Grid>
          {enableButton && (
            <Button
              fullWidth
              variant="outlined"
              sx={{
                my: 3,
                variant: block?.settings?.buttonVariant || 'contained',
                height: '34px',
                borderRadius: buttonBorderRadius || '0px',
                fontWeight: 800,
                bgcolor:
                  block?.settings?.buttonVariant !== 'outlined'
                    ? buttonBgColor
                    : '',
                color: buttonColor,
                '&:hover': {
                  bgcolor:
                    block?.settings?.buttonVariant !== 'outlined'
                      ? buttonBgColor
                      : '',
                  color: buttonColor,
                },
              }}
              onClick={() => {
                if (!user) {
                  dispatch({
                    type: ActionTypes.SET_ACTIVE_DIALOG,
                    payload: 'login',
                  });
                } else if (!block?.settings?.linkOnProductInfo) {
                  if (product?.deliveryOptions?.length) {
                    addToCart(product);
                  } else {
                    let currentPrice;
                    if (value && !coincidence) {
                      currentPrice = value;
                      if (minPrice && value < minPrice) currentPrice = minPrice;
                      if (maxPrice && value > maxPrice) currentPrice = maxPrice;
                    } else if (isOpenPrice && coincidence) {
                      currentPrice = coincidence.price;
                      // @ts-ignore
                      if (+coincidence.price > maxPrice) currentPrice = maxPrice;
                      // @ts-ignore
                      if (+coincidence.price < minPrice) currentPrice = minPrice;
                    } else {
                      currentPrice = (enableDiscount && basePrice) || price;
                    }
                    addToBasket(
                      state.smbAccount.id,
                      product?.id,
                      currentPrice,
                      imageLink,
                      dispatch,
                      cart,
                      [],
                    );
                  }
                } else info(product, block);
              }}
            >
              {buttonLabelNoPrice || 'כניסה לאתר'}
            </Button>
          )}
        </Stack>
      </Card>
      <Dialog
        open={countriesView.value}
        onClose={closeDialog}
        maxWidth="xs"
        fullWidth
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <IconButton onClick={closeDialog}>
            <Iconify icon="carbon:close-filled" />
          </IconButton>
        </Stack>
        <DialogTitle>
          <Typography sx={{ textAlign: 'end', fontWeight: 800 }}>{t('Supported Countries')}</Typography>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '400px', overflow: 'hidden', mb: 1 }}>
          <TextField
            placeholder="Country name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify icon="mdi:search" />
                </InputAdornment>
              ),
              startAdornment: (
                <>{!!searchText.length && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearchText('')} sx={{ cursor: 'pointer' }}>
                      <Iconify icon="iconamoon:close" />
                    </IconButton>
                  </InputAdornment>
                )}</>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <Stack
            sx={{
              overflowY: 'auto',
              maxHeight: 'calc(100% - 56px)',
              flex: 1,
            }}
          >
            {Array.isArray(countries) && countries && countries?.map((item: {
              value: string; flag: string;
            }, id: React.Key | null | undefined) => (
              <Stack key={`${id}_${value}`} direction="row" width={1} gap={1} my={0.5}>
                <Typography sx={{ width: '100%', textAlign: 'end' }}>{item.value}</Typography>
                <Image src={item.flag} sx={{ width: '25px' }} />
              </Stack>
            ))}
          </Stack>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
