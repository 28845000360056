'use client';

import { format } from 'date-fns';
import { useRouter } from 'next/navigation';
import { FC, useMemo, useState, useEffect, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import { Box, Grid, Button, Container, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useWindowSize } from 'src/hooks/use-window-size';

import { useTranslate } from 'src/locales';
import { SERVICES } from 'src/_mock/_services';
import { useGetMerchantsCityPeople } from 'src/api/merchant';
import { useGetMerchants } from 'src/api/shop';
import { useAuthContext } from 'src/auth/hooks';
import { getApartments } from 'src/api/citypeople';
import getRandomInt from 'src/helpers/getRandomInt';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import { DEFAULT_MERCHANT_IMAGE } from 'src/_mock/_companies';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Iconify from 'src/components/iconify';

import { BlockType } from 'src/types/generator';
import { IVirtualCard } from 'src/types/club-cards';
import { IProduct, PriceTypeEnum } from 'src/types/product';
import { Ib2bProduct, PriceInterface } from 'src/types/b2b';
import { ComponentTypeProps } from 'src/types/page-generator';
import { IMerchant, IMerchantCategory } from 'src/types/merchant';

import { Apartments } from './apartments';
import { getContentValueFromProps } from '../../utils';
import { fCurrency } from '../../../../utils/format-number';

const LIMIT = 1000;

export const ServiceList: FC<ComponentTypeProps> = ({ block, params }) => {
  const [page, setPage] = useState(0);

  const getFieldValue = getContentValueFromProps(block);
  const { state, dispatch } = useAppContext();
  const { user } = useAuthContext();
  const { merchants, totalCount } = useGetMerchantsCityPeople({
    limit: LIMIT,
    offset: page * LIMIT,
    relations: ['categories', 'smbAccount'],
  });
  const { selectedApartment, apartments } = state;
  const vouchers = state.products;
  const vouchersCount = state.productCount;

  const {
    state: { virtualCards },
  } = useAppContext();

  const isExistVouchers = useMemo(() => {
    if (!Array.isArray(vouchers)) return false;
    return Boolean(vouchersCount);
  }, [vouchers, vouchersCount]);

  const isExistVirtualCards = useMemo(() => {
    if (!Array.isArray(virtualCards)) return false;
    return Boolean(virtualCards.length);
  }, [virtualCards]);

  useEffect(() => {
    if (user) {
      getApartments(user.id, dispatch).catch((e) => {
        console.log(`Error during fetch apartments: ${e}`);
      });
    }
  }, [state.activeDialog]);

  useEffect(() => {
    if (user) {
      if (selectedApartment) {
        const updApartment = state.apartments.find((a) => a.id === selectedApartment.id);

        if (updApartment) {
          dispatch({ type: ActionTypes.SET_SELECTED_APARTMENT, payload: updApartment });
          dispatch({
            type: ActionTypes.GET_VIRTUAL_CARD,
            payload: Array.isArray(updApartment.vouchers)
              ? updApartment.vouchers.reduce((acc: IVirtualCard[], v: IProduct) => {
                  if (!Array.isArray(v.virtualCards)) return acc;

                  return [...acc, ...v.virtualCards];
                }, [])
              : [],
          });
          dispatch({
            type: ActionTypes.GET_PRODUCTS,
            payload: {
              products: updApartment?.vouchers || [],
              count: updApartment?.vouchers?.length || 0,
            },
          });
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (apartments?.length === 1) {
          dispatch({ type: ActionTypes.SET_SELECTED_APARTMENT, payload: apartments[0] });
          dispatch({
            type: ActionTypes.GET_VIRTUAL_CARD,
            payload: Array.isArray(apartments[0].vouchers)
              ? apartments[0].vouchers.reduce((acc: IVirtualCard[], v: IProduct) => {
                  if (!Array.isArray(v.virtualCards)) return acc;

                  return [...acc, ...v.virtualCards];
                }, [])
              : [],
          });
          dispatch({
            type: ActionTypes.GET_PRODUCTS,
            payload: {
              products: apartments[0]?.vouchers || [],
              count: apartments[0]?.vouchers?.length || 0,
            },
          });
        } else {
          dispatch({ type: ActionTypes.SET_SELECTED_APARTMENT, payload: null });
          dispatch({ type: ActionTypes.GET_VIRTUAL_CARD, payload: [] });
        }
      }
    }
  }, [user, state.selectedApartment, state.apartments, state.activeDialog]);

  // === Content Values ===
  const link: string = useMemo(() => getFieldValue('link'), [block?.link]);
  const backdropLink: string = useMemo(() => getFieldValue('backdropLink'), [block?.backdropLink]);
  const merchantRedirectLink: string = useMemo(
    () => getFieldValue('merchantRedirectLink') ?? '/order',
    [block?.merchantRedirectLink]
  );

  // === Settings Values ===
  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign') ?? false,
    [block?.settings.isAlternativeDesign]
  );

  if (!selectedApartment) return <Apartments block={block} params={params}/>;

  return (
    <Container maxWidth="sm">
      <Stack
        flexWrap="wrap"
        flexDirection="row-reverse"
        justifyContent="center"
        gap={2}
        pt={2}
        pb={12}
        sx={{
          margin: '0 auto',
          width: { xs: '100%' },
        }}
      >
        <>
          {block?.settings?.variant === 'v1' ? (
            <>
              {!isExistVouchers && (
                <Typography variant="h6" color="secondaryContrastText">
                  NO COUPONS
                </Typography>
              )}
              {isExistVouchers &&
                vouchers.map((voucher: Ib2bProduct) => (
                  <ServiceCard
                    key={voucher.id}
                    voucher={voucher}
                    link={link}
                    backdropLink={backdropLink}
                    isAlternativeDesign={isAlternativeDesign}
                    merchants={merchants}
                    merchantsCount={totalCount}
                    merchantLink={merchantRedirectLink}
                  />
                ))}
            </>
          ) : (
            <>
              {!isExistVirtualCards && (
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ textOverflow: 'ellipsis' }}
                />
              )}
              {isExistVirtualCards &&
                virtualCards && virtualCards?.map((card: IVirtualCard) => (
                  <ServiceCard
                    key={card.id}
                    card={card}
                    link={link}
                    backdropLink={backdropLink}
                    isAlternativeDesign={isAlternativeDesign}
                    merchants={merchants}
                    merchantsCount={totalCount}
                    merchantLink={merchantRedirectLink}
                    block={block}
                  />
                ))}
            </>
          )}
        </>
      </Stack>
    </Container>
  );
};

type ServiceCardProps = {
  voucher?: Ib2bProduct;
  card?: IVirtualCard;
  isAlternativeDesign: boolean;
  merchants: IMerchant[];
  merchantsCount: number;
  merchantLink: string;
  link?: string;
  backdropLink?: string;
  block?: BlockType | undefined;
};

const BUTTON_TEXT = 'רשימת ספקים';

function ServiceCard({
  voucher,
  card,
  link,
  backdropLink,
  isAlternativeDesign,
  merchants,
  merchantsCount,
  merchantLink,
  block,
}: ServiceCardProps) {
  const { t } = useTranslate();
  const [localMerchants, setMerchants] = useState<IMerchant[]>([]);
  const router = useRouter();

  const { width } = useWindowSize();
  const isSmallDisplay = width < 410;

  const { state, dispatch } = useAppContext();
  const merchantsPanel = useBoolean(false);
  const mainImageURLVoucher = voucher?.mainImageURL;
  const mainImageURLCard = card?.cardType?.mainImageURL;
  const mainImageURL = block?.settings?.variant === 'v1' ? mainImageURLVoucher : mainImageURLCard;
  const titleVoucher = voucher?.title;
  const titleCard = card?.cardType?.name;
  const title = block?.settings?.variant === 'v1' ? titleVoucher : titleCard;
  const description = voucher?.description;
  const createdAt = voucher?.createdAt;
  const balanceVoucher = voucher?.prices.find(
    (p: PriceInterface) => p.type === PriceTypeEnum.MAX_PRICE
  )?.value;
  const balanceCard = card?.balance;
  const balance = block?.settings?.variant === 'v1' ? balanceVoucher : balanceCard;
  const voucherLocalMainImage = useMemo(
    () => SERVICES[getRandomInt(0, SERVICES.length - 1)].image,
    []
  );
  const date = createdAt ? format(new Date(createdAt), 'dd.MM.yyyy') : '';
  const imageLink = mainImageURL ? `${BASIC_URL_FOR_IMAGES}${mainImageURL}` : voucherLocalMainImage;
  const getIsExistLink = useCallback((l: string) => l && l !== '#', []);

  useEffect(() => {
    if (Array.isArray(merchants)) {
      setMerchants(
        merchants.filter((m: IMerchant) => {
          if (!Array.isArray(m.categories)) return false;

          return m.categories
            .map((c: IMerchantCategory) => c.title)
            .includes(card?.cardType?.name || '');
        })
      );
    }
  }, []);

  const getPreparedLink = (rawLink: string) => {
    if (rawLink && getIsExistLink(rawLink))
      return `${rawLink[0] === '/' ? rawLink : `/${rawLink}`}`;
    return '#';
  };
  const getImageLink = (imgLink: string) =>
    imgLink ? `${BASIC_URL_FOR_IMAGES}${imgLink}` : DEFAULT_MERCHANT_IMAGE;

  useEffect(() => {
    if (backdropLink) {
      if (!state.selectedApartment?.id && backdropLink) router.push(backdropLink);
    }
  }, [backdropLink]);

  const handleClick = () => {
    dispatch({
      type: ActionTypes.SET_SELECTED_PRODUCT,
      payload: { ...voucher, localMainImage: imageLink },
    });

    if (link && getIsExistLink(link)) router.push(getPreparedLink(link));
  };

  const handleClickMerchant = (id: number) => () => {
    dispatch({
      type: ActionTypes.SET_SELECTED_MERCHANT,
      payload: merchants.find((m: IMerchant) => +m.id === +id),
    });

    const array = block?.settings?.variant === 'v1' ? voucher : card;

    dispatch({
      type: ActionTypes.SET_SELECTED_PRODUCT,
      payload: { ...array, localMainImage: imageLink },
    });
    dispatch({
      type: ActionTypes.SET_SELECTED_VIRTUAL_CARD,
      payload: card,
    });

    if (merchantLink && getIsExistLink(merchantLink)) router.push(getPreparedLink(merchantLink));
  };

  const cardNumber = (
    <Stack
      sx={{ direction: 'ltr', gap: '3px' }}
      direction={isSmallDisplay ? 'column' : 'row'}
      alignItems={isSmallDisplay ? 'flex-start' : 'center'}
    >
      <Typography fontSize={10} lineHeight="12px" fontWeight={1000}>
        {`קוד שובר ${isSmallDisplay ? '' : ':'}`}
      </Typography>
      <Typography fontSize={12} lineHeight="12px" fontWeight={1000}>
        {card?.cardNumber}
      </Typography>
    </Stack>
  );

  const cardBalance = (
    <Stack
      sx={{ direction: 'ltr', gap: '5px', ...(isSmallDisplay && { pb: '5px' }) }}
      direction={isSmallDisplay ? 'column' : 'row'}
      alignItems="center"
    >
      <Typography
        variant="body1"
        color="primary.light"
        textAlign="center"
        fontSize={isSmallDisplay ? 16 : 10}
        lineHeight="20px"
      >
        {`יתרה למימוש ${isSmallDisplay ? '' : ':'}`}
      </Typography>
      <Typography
        variant="body1"
        color="primary.light"
        textAlign="center"
        fontSize={isSmallDisplay ? 20 : 12}
        lineHeight="12px"
      >
        {fCurrency(balance ?? 0) || '0'}
      </Typography>
    </Stack>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={isAlternativeDesign ? 1.4 : 1}
      sx={{
        ...(!isAlternativeDesign && {
          borderRadius: 2,
          boxShadow: '0px 2px 5px 0px rgba(57, 159, 164, 0.25)',
          '&:hover': { bgcolor: 'rgba(57, 159, 164, 0.25)' },
        }),
        ...(isAlternativeDesign && {
          flexDirection: 'column',
          width: '100%',
          minHeight: 140,
          border: (theme) => `solid 1px ${theme.palette.primary.main}`,
          borderRadius: 2.5,
        }),
      }}
      onClick={() => {
        merchantsPanel.onToggle();
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={isAlternativeDesign ? 'space-between' : 'normal'}
        gap={isAlternativeDesign ? 0 : 1}
        mx={1}
        sx={{
          width: '100%',
          ...(isAlternativeDesign && { width: '100%', height: '100%' }),
        }}
      >
        {isAlternativeDesign ? (
          <Stack sx={{ pl: 1, width: '45%' }}>
            <Typography
              variant="body1"
              fontSize={25}
              fontWeight={100}
              sx={{ lineHeight: 1, mb: 1 }}
            >
              {title}
            </Typography>
            {block?.settings?.variant === 'v1' ? (
              <Typography fontSize={12} lineHeight="12px">
                {description} {date}
              </Typography>
            ) : (
              <>{cardNumber}</>
            )}
            <Box
              sx={{
                border: (theme) => `solid 1px ${theme.palette.primary.light}`,
                borderRadius: '20px',
                px: '15px',
                pt: '5px',
                pb: '2px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                my: 1,
              }}
            >
              {cardBalance}
            </Box>
            <Box
              sx={{
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              // onClick={() => {
              //   merchantsPanel.onToggle();
              // }}
            >
              <Box
                sx={{
                  width: '16px',
                  height: '16px',
                  m: '4px',
                  backgroundSize: 'contain',
                  backgroundImage: 'url(/assets/images/city-people/asterisk-mini.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '50%',
                }}
              />
              <Typography fontSize={12} fontWeight={100} sx={{ textAlign: 'center' }}>
                {BUTTON_TEXT}
              </Typography>
              <Iconify
                width={25}
                color="primary.light"
                icon={`material-symbols-light:keyboard-arrow-${
                  merchantsPanel.value ? 'up' : 'down'
                }`}
              />
            </Box>
          </Stack>
        ) : (
          <Stack>
            <Typography variant="h6" color="secondaryContrastText">
              {title}
            </Typography>
            <Typography fontSize={10}>
              {description} {date}
            </Typography>
            <Typography
              bgcolor="#00B8D9"
              variant="h6"
              color="white"
              textAlign="center"
              lineHeight={1}
            >
              ₪{balance || '0'}
            </Typography>
            <Button variant="outlined" sx={{ borderRadius: 2, mt: 1 }} onClick={handleClick}>
              {BUTTON_TEXT}
            </Button>
          </Stack>
        )}
        <Box
          width={isAlternativeDesign ? '50%' : 130}
          height={isAlternativeDesign ? '100%' : 100}
          borderRadius={isAlternativeDesign ? '0 16px 16px 0' : '16px'}
          sx={{
            background: '#FFFFFF',
            backgroundImage: `url('${imageLink || ''}')`,
            backgroundPosition: '50%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Stack>
      {merchantsPanel.value && (
        <Grid
          container
          spacing={1}
          mt={3}
          pt={2}
          mx={1.6}
          sx={{
            borderTopWidth: '1px',
            borderTopColor: 'primary.main',
            borderTopStyle: 'solid',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <>
            {block?.settings?.variant === 'v1' ? (
              <Box
                sx={{
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  rowGap: 2,
                  columnGap: { sm: 4, xs: 1 },
                }}
              >
                {!merchantsCount && (
                  <Typography
                    sx={{ margin: '0 auto' }}
                    textAlign="center"
                    variant="h6"
                    color="secondaryContrastText"
                  >
                    NO MERCHANTS
                  </Typography>
                )}
                {!!merchantsCount &&
                  merchants.map((merchant: IMerchant) => (
                    <Box
                      key={merchant.id}
                      width={95}
                      height={95}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        margin: '0 auto',
                        border: (theme) => `solid 1px ${theme.palette.primary.main}`,
                        borderRadius: 2,
                        background: '#FFFFFF',
                        backgroundImage: `url('${getImageLink(merchant.mainImageURL) || ''}')`,
                        backgroundPosition: '50%',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                      onClick={handleClickMerchant(+merchant.id)}
                    />
                  ))}
              </Box>
            ) : (
              <Box
                sx={{
                  width: '90%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  rowGap: 2,
                  columnGap: { sm: 4, xs: 1 },
                }}
              >
                {!localMerchants?.length && (
                  <Typography
                    sx={{ margin: '0 auto' }}
                    textAlign="center"
                    variant="h6"
                    color="secondaryContrastText"
                  >
                    NO MERCHANTS
                  </Typography>
                )}
                {!!localMerchants?.length &&
                  localMerchants?.map((merchant: IMerchant) => (
                    <Box
                      key={merchant.id}
                      width={95}
                      height={95}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        cursor: 'pointer',
                        margin: '0 auto',
                        border: (theme) => `solid 1px ${theme.palette.primary.main}`,
                        borderRadius: 2,
                        background: '#FFFFFF',
                        backgroundImage: `url('${getImageLink(merchant.mainImageURL) || ''}')`,
                        backgroundPosition: '50%',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                      onClick={handleClickMerchant(+merchant.id)}
                    />
                  ))}
              </Box>
            )}
          </>
        </Grid>
      )}
    </Box>
  );
}
