'use client';

// @mui
import Grid from '@mui/material/Unstable_Grid2';
// types

import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import uuidv4 from '../../../../utils/uuidv4';
import { API } from '../../../../helpers/api';
import { useTranslate } from '../../../../locales';
import { BOT_ID } from '../../../../config-global';
import { getContentValueFromProps } from '../../utils';
import { IPaymentCard } from '../../../../types/payment';
import { IAddressItem } from '../../../../types/address';
import { ButtonField } from '../../../../types/generator';
import FormProvider from '../../../../components/hook-form';
import AccountStatusHistory from './account-status-history';
import { BlockType } from '../../../../types/page-generator';
//
import AccountBillingHistory from './account-billing-history';
import { useResponsive } from '../../../../hooks/use-responsive';
import { IUserAccountBillingHistory } from '../../../../types/user';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

// ----------------------------------------------------------------------

type Props = {
  plans: {
    subscription: string;
    price: number;
    primary: boolean;
  }[];
  cards: IPaymentCard[];
  invoices: IUserAccountBillingHistory[];
  addressBook: IAddressItem[];
  block: BlockType | undefined;
};

export default function AccountBilling({ cards, plans, invoices, addressBook, block }: Props) {
  const getFieldValue = getContentValueFromProps(block);
  const defaultValues = {
    startDate: new Date(),
    dueDate: new Date(),
  };
  const { dispatch, state } = useAppContext();
  const [startDate, setStartDate] = useState<Date | null | any>(null);
  const [endDate, setEndDate] = useState<Date | null | any>(null);
  const isMobile = useResponsive('down', 'sm');
  const [discount, setDiscount] = useState('');
  const [discounts, setDiscounts] = useState([]);
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [discountError, setDiscountError] = useState('');

  useEffect(() => {
    setStartDateError('')
  }, [startDate]);
  useEffect(() => {
    setEndDateError('')
  }, [endDate]);
  useEffect(() => {
    setDiscountError('')
  }, [discount]);

  const { t } = useTranslate();
  const NewSchema = Yup.object().shape({
    startDate: Yup.date().nullable().required('Date required'),
    dueDate: Yup.date()
      .nullable()
      .required('Date required')
      .min(Yup.ref('startDate'), 'The end date must be later than the start date.'),
  });


  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons],
  );

  const methods = useForm({
    resolver: yupResolver(NewSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (item) => {
    try {
      if (startDate === null) setStartDateError('Required');
      if (endDate === null) setEndDateError('Required');
      if (discount === '') setDiscountError('Required');

      const body = {
        name: `New bonus rule name - ${uuidv4()}`,
        startDate: startDate?.toISOString(),
        dueDate: endDate?.toISOString(),
        smbAccountIds: [
          `${state.smbAccount.id}`,
        ],
        amount: +discount,
      };
      const { data } = await API({
        url: `/client/bonus-rule`,
        method: 'POST',
        data: body,
      });
      if (data.success === true) {
        setDiscount('');
        setStartDate(null);
        setEndDate(null);
      }
    } catch (error) {
      console.error(error);
    }
  });

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const getDiscount = async () => {
    onSubmit();
  };

  const getAllDiscounts = async () => {
    const { data } = await API({
      url: `/client/bonus-rule/${state.smbAccount.id}`,
      method: 'GET',
      params: {
        botId: BOT_ID,
        active: true,
        offset: 0,
      },
    });
    setDiscounts(data.payload);
  };

  useEffect(() => {
    getAllDiscounts();
  }, [discount]);

  const handleStartDateChange = (newValue: dayjs.Dayjs | null) => {
    setStartDate(newValue);
    if (newValue && endDate && newValue.isAfter(endDate)) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    setEndDate(newValue);
  };

  return (
    <Grid container spacing={5} disableEqualOverflow sx={{ margin: 0 }}>
      {isMobile ? (
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack direction={isMobile ? 'column' : 'row'} width={1}>
            <Stack sx={{ mt: 4 }} width={1}>
              <Typography sx={{ fontSize: '20px' }}>אחוז הנחה בתוקף 20%</Typography>
              <Stack
                direction={isMobile ? 'column-reverse' : 'row'}
                alignItems={isMobile ? 'flex-start' : 'baseline'}
                mb={2}
                gap={1}
                width={1}
              >
                {actionButtons.map((menuButton: any, id: number) => (
                  <Button
                    key={id}
                    variant={menuButton?.buttonVariant || 'contained'}
                    color={menuButton?.buttonBgColor || 'secondary'}
                    size={menuButton?.buttonSize}
                    sx={{
                      borderRadius: '5px',
                      background: menuButton.isBackgroundGradient
                        ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                        : menuButton?.background,
                      fontSize: '16px',
                      px: 1.1,
                      '&:hover': {
                        color: menuButton?.color,
                        background: menuButton.isBackgroundGradient
                          ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                          : menuButton?.background,
                      },
                    }}
                    href={
                      (!menuButton?.link.includes('modal') &&
                        !menuButton.isDialogToggler &&
                        menuButton?.link) ||
                      ''
                    }
                    onClick={() => {
                      getDiscount();
                      // if (menuButton.isDialogToggler && menuButton.modalId) {
                      //   setActiveDialog(menuButton.modalId);
                      //   checkDiscount();
                      // }
                    }}
                  >
                    {menuButton?.label || `[${t('no label')}]`}
                  </Button>
                ))}
                <Stack
                  direction="row"
                  width={isMobile ? 1 : '20%'}
                  alignItems="center"
                  gap={1}
                  mt={isMobile ? 3 : 2}
                >
                  <Typography sx={{ fontSize: '20px' }}>שינוי אחוז הנחה</Typography>
                  <TextField
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    sx={{ '& input': { padding: '10px' }, width: '20%', borderRadius: '10px', border: discountError !== '' ? '1px solid red': '' }}
                  />
                    <Typography sx={{ fontSize: '20px' }}>%</Typography>
                </Stack>
              </Stack>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" gap={2} width={isMobile ? 'auto' : 0.6} mr={3}>
                  <Stack>
                    <DateTimePicker
                      sx={{ width: '100%', borderRadius: '10px', border: startDateError !== '' ? '1px solid red' : '' }}
                      label="From"
                      name="startDate"
                      value={startDate}
                      // @ts-ignore
                      minDateTime={dayjs()}
                      onChange={handleStartDateChange}
                      ampm={false}
                    />
                    {startDateError !== '' && (
                      <FormHelperText error={startDateError !== ''}>{startDateError}</FormHelperText>
                    )}
                  </Stack>
                  <Stack>
                    <DateTimePicker
                      sx={{ width: '100%', borderRadius: '10px', border: endDateError !== '' ? '1px solid red' : '' }}
                      label="Until"
                      name="dueDate"
                      value={endDate}
                      // @ts-ignore
                      minDateTime={startDate ? dayjs(startDate).add(1, 'minute') : dayjs()}
                      onChange={handleEndDateChange}
                      ampm={false}
                    />
                    {endDateError !== '' && (
                      <FormHelperText error={endDateError !== ''}>{endDateError}</FormHelperText>
                    )}
                  </Stack>
                </Stack>
              </LocalizationProvider>
            </Stack>
          </Stack>
        </FormProvider>
      ) : (
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack sx={{ mt: 4 }}>
            <Typography sx={{ fontSize: '20px' }}>אחוז הנחה בתוקף 20%</Typography>
          </Stack>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'baseline'}
            mb={2}
            gap={1}
          >

            {actionButtons.map((menuButton: any, id: number) => (
              <Button
                key={id}
                variant={menuButton?.buttonVariant || 'contained'}
                color={menuButton?.buttonBgColor || 'secondary'}
                size={menuButton?.buttonSize}
                sx={{
                  borderRadius: '5px',
                  background: menuButton.isBackgroundGradient
                    ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                    : menuButton?.background,
                  fontSize: '16px',
                  px: 1.1,
                  '&:hover': {
                    color: menuButton?.color,
                    background: menuButton.isBackgroundGradient
                      ? `linear-gradient(90deg, ${menuButton?.firstGradientColor} ${menuButton?.firstGradientStop}%, ${menuButton?.secondGradientColor} ${menuButton?.secondGradientStop}%)`
                      : menuButton?.background,
                  },
                }}
                href={
                  (!menuButton?.link.includes('modal') &&
                    !menuButton.isDialogToggler &&
                    menuButton?.link) ||
                  ''
                }
                onClick={() => {
                  getDiscount();
                  // if (menuButton.isDialogToggler && menuButton.modalId) {
                  //   setActiveDialog(menuButton.modalId);
                  //   checkDiscount();
                  // }
                }}
              >
                {menuButton?.label || `[${t('no label')}]`}
              </Button>
            ))}
            <Stack
              direction="row"
              width={isMobile ? 1 : '20%'}
              alignItems="center"
              gap={1}
              mt={isMobile ? 3 : 2}
            >
              <Typography sx={{ fontSize: '20px' }}>שינוי אחוז הנחה</Typography>
              <TextField
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                sx={{ '& input': { padding: '10px' }, width: '20%', borderRadius: '10px', border: discountError !== '' ? '1px solid red': '' }}
              />
              <Typography sx={{ fontSize: '20px' }}>%</Typography>
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="row" gap={2} width={isMobile ? 'auto' : 0.6} mr={3}>
                <Stack>
                  <DateTimePicker
                    sx={{ width: '100%', borderRadius: '10px', border: startDateError !== '' ? '1px solid red' : '' }}
                    label="From"
                    name="startDate"
                    value={startDate}
                    // @ts-ignore
                    minDateTime={dayjs()}
                    onChange={handleStartDateChange}
                    ampm={false}
                  />
                  {startDateError !== '' && (
                    <FormHelperText error={startDateError !== ''}>{startDateError}</FormHelperText>
                  )}
                </Stack>
                <Stack>
                  <DateTimePicker
                    sx={{ width: '100%', borderRadius: '10px', border: endDateError !== '' ? '1px solid red' : '' }}
                    label="Until"
                    name="dueDate"
                    value={endDate}
                    // @ts-ignore
                    minDateTime={startDate ? dayjs(startDate).add(1, 'minute') : dayjs()}
                    onChange={handleEndDateChange}
                    ampm={false}
                  />
                  {endDateError !== '' && (
                    <FormHelperText error={endDateError !== ''}>{endDateError}</FormHelperText>
                  )}
                </Stack>
              </Stack>
            </LocalizationProvider>
          </Stack>
          <Stack gap={2} direction="row" width={1}>
            {/* <RHFSelect sx={{ width: 0.5 }} native name="class" label={`${t('Select a club')}`}> */}
            {/*  {[ */}
            {/*    'קטגוריות', */}
            {/*    'גיפט קארד לאופנה ורשתות', */}
            {/*    'תורמים ומחזקים ', */}
            {/*    'שוברים למסעדות', */}
            {/*    'אחי ישראלי', */}
            {/*    'מתנות ליום הולדת', */}
            {/*    'גיפט קארד לנופש וחופשות', */}
            {/*    'שוברים לארוחת בוקר', */}
            {/*    'מתנות עד הבית', */}
            {/*    'שוברים לספא', */}
            {/*    'מתנה לגיוס', */}
            {/*    'חזרה לביה"ס', */}
            {/*    'מתנות לאישה', */}
            {/*    'מתנות למורה ולגננת', */}
            {/*  ].map((category) => ( */}
            {/*    <option key={category}>{category}</option> */}
            {/*  ))} */}
            {/* </RHFSelect> */}
          </Stack>
        </FormProvider>
      )}
      <Grid xs={12} md={12} sx={{ padding: 0 }}>
        <AccountStatusHistory discounts={discounts} />
      </Grid>
      <Grid xs={12} md={12} sx={{ padding: 0 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" gap={2} width={isMobile ? 'auto' : 1} mr={3} mb={4}>
            <DatePicker sx={{ width: '100%' }} label={`${t('From')}`} />
            <DatePicker sx={{ width: '100%' }} label={`${t('Until')}`} />
          </Stack>
        </LocalizationProvider>
        <AccountBillingHistory invoices={invoices} />
      </Grid>
    </Grid>
  );
}
